<script lang="ts" setup>
import { ref, shallowRef, watch } from "vue";
import { useRoute } from "vue-router";

import AuthLayout from "./AuthLayout.vue";
import MainLayout from "./MainLayout.vue";

const LAYOUTS = {
  AuthLayout,
  MainLayout,
};

const isLayout = (
  maybeLayout: unknown
): maybeLayout is keyof typeof LAYOUTS => {
  if (typeof maybeLayout === "string") {
    return Object.keys(LAYOUTS).includes(maybeLayout);
  }
  return false;
};

const layout = shallowRef(LAYOUTS.AuthLayout);
const loading = ref(true);

const route = useRoute();

watch(
  () => route.meta,
  async () => {
    layout.value = getLayout();
    loading.value = route.path == "/";
  }
);

function getLayout() {
  const { meta, path } = route;

  if (path == "/") {
    return AuthLayout;
  } else if (isLayout(meta.layout)) {
    return LAYOUTS[meta.layout];
  }

  return MainLayout;
}
</script>

<template>
  <div class="wrapper">
    <BasePreloader v-if="loading" class="preloader" />
    <component v-else :is="layout" />
    <BaseNotificationList />
    <PTeleportContainer />
    <PToastContainer class="toast" />
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  height: calc(100vh);
}
.preloader {
  height: calc(100vh);
  width: calc(100vw);
  display: flex;
  align-items: center;
  justify-content: center;
}

.toast {
  white-space: pre-wrap;

  :deep(.p-toast) {
    white-space: pre-wrap;
  }

  :deep(.content-text) {
    -webkit-line-clamp: unset !important;
  }
}
</style>
