import { BroadcastVariables, BroadcastVariablesMap } from "~/entities/broadcast";

export function stringifyDelay(begin: string | undefined, end: string | undefined) {
	return `${begin || ""}-${end || ""}`;
}


export function broadcastVariablesMapToBroadcastVariables(map: BroadcastVariablesMap): BroadcastVariables {
	return {
		headerVars: Object.values(map.headerVars),
		bodyVars: Object.values(map.bodyVars),
		footerVars: Object.values(map.footerVars),
		buttonVars: Object.values(map.buttonVars),
	}
}


/**
 * Преобразует BroadcastVariables в BroadcastVariablesMap
 * Есть проблема преобразования, поскольку в действительности 
 * обратного преобразования нет. Но исходя из текущей структуры данных,
 * его можно сделать, поскольку ключи объекта соответствуют индексам в массиве BroadcastVariables
 * Если структура поменяется, придется отказаться от BroadcastVariables и хранить полную карту в базе данных.
 * **/
export function broadcastVariablesToBroadcastVariablesMap(variables: BroadcastVariables): BroadcastVariablesMap {
	return {
		headerVars: Object.fromEntries(variables.headerVars.map((e, i) => ([i + 1, e]))),
		bodyVars: Object.fromEntries(variables.bodyVars.map((e, i) => ([i + 1, e]))),
		footerVars: Object.fromEntries(variables.footerVars.map((e, i) => ([i + 1, e]))),
		buttonVars: Object.fromEntries(variables.buttonVars.map((e, i) => ([i + 1, e]))),
	}
}