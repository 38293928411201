<script setup lang="ts">
import { ref, computed } from 'vue';
import { SYNC_CHANNELS_BUTTON_TEXT } from '~/constants/profileWzChannel.constants';
import { useChannels } from '~/stores/channel.store';

const channelsStore = useChannels();

const emptyChannelList = ref<boolean>(true);

const isNoChannels = computed(() => !channelsStore.channels.length);

const onRedirectWazzup = () => {
  window.location.href = 'https://wazzup24.com';
};

const __channel_modal__isVisible = ref<boolean>(false);

function showChannelModal() {
  __channel_modal__isVisible.value = true
}

function closeChannelModal() {
  __channel_modal__isVisible.value = false
}

function submitSyncChannelsData(key: string) {
  channelsStore.syncChannelsByWazzupUserKey(key);
  closeChannelModal();
}

</script>

<template>
  <div class="profile-channel">
    <div class="profile-channel_area area_sync-channels-button">
    <PButton
      :variant="'default'"
      :size="'small'"
      :color="'primary'"
      :disabled="false"
      :nuxt="false"
      :type="'button'"
      :block="false"
      @click="showChannelModal()"
    >
      {{ SYNC_CHANNELS_BUTTON_TEXT }}
    </PButton>
    </div>
    <div class="profile-channel__header">
      <div class="profile-channel__header-content">
        <h4 class="profile-channel__title">Каналы</h4>
        <p class="profile-channel__description">
          Здесь отображаются все каналы компании. Вы можете выбрать для использования в Sendbot один WABA канал и один Telegram канал.
        </p>
      </div>
    </div>
    <PAlert
      v-if="isNoChannels"
      v-model="emptyChannelList"
      :show-cross="false"
      :hide-left-icon="false"
      color="warning"
      :button-text="'Перейти в Wazzup'"
      @click:button="onRedirectWazzup"
    >
      {{ $t('profile.alert.wzChannelListEmpty') }}
    </PAlert>
    <ProfileChannelList v-else />
  </div>

  <SyncChannelModal
    :visible="__channel_modal__isVisible"
    @submit="(value: string) => submitSyncChannelsData(value)"
    @close="closeChannelModal()"
  />
</template>

<style scoped>
.profile-channel {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid var(--common-smog);
  gap: 24px;
}

.profile-channel__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-channel__header-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.profile-channel__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
}

.profile-channel__description {
  font-size: 14px;
  font-weight: 400;
  line-height: 125%;
  color: var(--common-dark-water, #5d7285);
}

.area_sync-channels-button button {
  float: right;
}
</style>
