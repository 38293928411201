import { CatchWzApi } from '~/error/wz-api-catch';
import { BaseCrudApi } from './abstract/base-crud.api';
import { Channel, ChannelsTransportType } from '~/entities/channel';
import { ChannelSubscription } from '~/types/channel-subscription.type';

export class RouterApi extends BaseCrudApi {
	constructor() {
		super('router');
	}

	activateSubscription(subscriptionId: string) {
		return this.api
      .post<Array<Channel>>('subscriptions', {
				channelId: subscriptionId,
				eventGroup: 'all'
			})
      .then((result) => result.data);
	}
}
