<script lang="ts" setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { AppStateT } from "~/main";
import { inject } from "vue";
const appState: AppStateT = inject("state")!;

const route = useRoute();

const isVisibleHeader = computed(
  () => !["templates", "chats"].includes(route.name as string)
);
const background = computed(() => {
  return route.meta.background ? { background: route.meta.background } : null;
});
</script>

<template>
  <div class="main">
    <Header v-show="isVisibleHeader" />
    <div class="content" :style="background">
      <router-view />
      <div v-if="appState.isLoading" class="splash"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$header-height: 64px; // todo: верстка лэйаута

.main {
  overflow-x: hidden;
  flex: 1;

  .content {
    position: relative;
    height: calc(100vh - $header-height);

    .splash {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 1000;
      top: 0;
      background-color: #fff;
    }
  }
}
</style>
