import { defineStore } from "pinia";

import { api } from "~/api";
import {
  Channel,
  ChannelState,
  ChannelsTransportType,
} from "~/entities/channel";
import { useTemplates } from "./template.store";

type Channels = Array<Channel>;

interface State {
  channels: Channels;
  selectedWabaChannel: Channel | null;
  selectedTelegramChannel: Channel | null;
  isFetching: boolean;
  telegramError: string;
}

export const useChannels = defineStore("channels", {
  state: (): State => ({
    channels: [],
    isFetching: false,
    telegramError: "",
    selectedWabaChannel: null,
    selectedTelegramChannel: null,
  }),

  actions: {
    async fetchChannels() {
      this.channels = await api.channels.getListOfChannels();
    },
    async setSelectedWabaChannel(
      channelId: string | null
    ): Promise<Channel | null> {
      const templateStore = useTemplates();
      const channel = this.$state.channels.find((c) => c.id == channelId);
      if (!channel) {
        return null;
      }
      this.selectedWabaChannel = channel;

      return new Promise((r) => {
        templateStore.fetchChannelTemplates().then(() => {
          r(this.selectedWabaChannel);
        });
      });
    },
    syncChannelsByWazzupUserKey(key: string) {
      api.channels.syncWazzupChannelsByAccountKey(key).then((res) => {
        if (res) {
          for (const subscription of res.data) {
            api.router.activateSubscription(subscription.id);
          }
        }
        this.fetchChannels();
      });
    },
    setTelegramError(payload: string) {
      this.telegramError = payload;
    },
    setFetching(payload: boolean) {
      this.isFetching = payload;
    },
  },

  getters: {
    getChannels: (state: State) => state.channels,
    getFetching: (state: State) => state.isFetching,
    getNonClosedChannels: (state: State) => {
      return state.channels.filter((c) =>
        [
          ChannelState.blocked,
          ChannelState.rejected,
          ChannelState.disabled,
        ].includes(c.state)
      );
    },
    wabaChannels: (state: State) => {
      return state.channels.filter(
        (c) => c.transport == ChannelsTransportType.wapi
      );
    },

    telegramChannels: (state: State) => {
      return state.channels.filter(
        (c) => c.transport == ChannelsTransportType.telegram
      );
    },

    selectedWabaChannelId: (state: State): string | null => {
      return state.selectedWabaChannel?.id || null;
    },
  },
});
