<script lang="ts" setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";

import { config } from "~/config";
import { CookieName, setCookie } from "~/utils/cookie";
import { useAuth } from "./stores/auth.store";
import {
  restoreWzIntegrationTokenFromLocalStorage,
  setWzIntegrationTokenToLocalStorage,
} from "./composables/wz-integration-token-local-storage";

const loginTokenUri = "/account/login";
const wzIntegrationUri = "/wzlogin";

const router = useRouter();
const authStore = useAuth();

const loginByToken = async () => {
  const [_, phoneNumber, loginToken] = window.location.href
    .split(loginTokenUri)[1]
    .split("/");

  const isSuccess = await authStore.loginToken({ loginToken, phoneNumber });
  if (isSuccess) {
    await router.push({ name: "profile" });
  }
};

const loginByWzIntegration = async () => {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  const token = searchParams.get("token");

  if (token) {
    await setWzIntegrationTokenToLocalStorage(authStore, token);
  }
};

onMounted(async () => {
  if (window.location.href.includes(loginTokenUri)) {
    await loginByToken();
    return;
  }
  if (window.location.href.includes(wzIntegrationUri)) {
    await loginByWzIntegration();
  }

  await restoreWzIntegrationTokenFromLocalStorage(authStore);

  await router.isReady();
  authStore.setRouter(router);

  if (window.location.href.indexOf("utm_") > 0) {
    setCookie({
      name: CookieName.domainUtm,
      value: window.location.href,
      exdays: 1,
    });
  }
  if (document.referrer && document.referrer.indexOf("app-sender") === -1) {
    setCookie({
      name: CookieName.referrer,
      value: document.referrer,
      exdays: 1,
    });
  }
});
</script>

<template>
  <AppHead />
  <!-- Google Tag Manager (noscript) -->
  <noscript v-if="config.nodeEnv === 'production'">
    <iframe
      :src="`https://www.googletagmanager.com/ns.html?id=${config.googleGTMId}`"
      height="0"
      style="display: none; visibility: hidden"
    >
    </iframe>
  </noscript>
  <!-- End Google Tag Manager (noscript) -->

  <el-config-provider>
    <LayoutContainer />
  </el-config-provider>
</template>

<style>
#app {
  color: var(--el-text-color-primary);
  background: #f5f5f5;
}
</style>
