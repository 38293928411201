<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import {
  PTooltip,
  IconTelegramXL as TelegramIcon,
  IconWhatsAppBusinessApiXL as WabaIcon,
  IconInfoSm as InfoIcon,
} from '@profeat/ui-kit';

import {
  Channel,
  ChannelState,
  ChannelsTransportType,
  MessageLimitTier,
} from '~/entities/channel';
import { useUser } from '~/stores/user.store';

const URL_HOME = 'https://business.facebook.com/wa/manage/home';
const URL_CONFIRM = 'https://business.facebook.com/settings/security';
const ICONS = {
  [ChannelsTransportType.telegram]: TelegramIcon,
  [ChannelsTransportType.wapi]: WabaIcon,
};

const userStore = useUser();

const props = defineProps<{ channel: Channel; divider: boolean }>();

const emits = defineEmits<{
  (event: 'delete', channelId: string): void;
  (event: 'edit', channelId: string): void;
  (event: 'switch', channelId: string): void;
  (event: 'activate', channelId: string): void;
  (event: 'deactivate', channelId: string): void;
}>();

const ChannelIcon = computed(() => {
  // @ts-ignore
  return ICONS[props.channel.transport];
});

// Канал активный
// Зеленый статус "Активен"
const isActive = computed(() => props.channel.state === ChannelState.active);

// Канал не активен
// Красный статус "Заблокирован"
const isDisabled = computed(() => {
  return [
    ChannelState.disabled,
    ChannelState.blocked,
    ChannelState.rejected,
  ].includes(props.channel.state);
});

const getTier = computed(() =>{
  let tierString: string = MessageLimitTier.NOT_ASSIGNED;
  if (props.channel.tier) {
    tierString = props.channel.tier.replace('TIER_', '');
  }
  return tierString;
})

// Флаг для отображения кнопок
// Кнопки не отображаются для интегрированных юзеров
const { isWzIntegration } = storeToRefs(userStore);

const onDelete = () => {
  emits('delete', props.channel.id);
};

const onEdit = () => {
  emits('edit', props.channel.id);
};
</script>

<template>
  <div class="profile-channel__list-item">
    <div class="profile-channel__list-item-info">
      <div class="profile-channel__list-item-icon">
        <ChannelIcon />
      </div>
      <div class="profile-channel__list-item-name">{{ channel.phone || channel.username }}</div>
      <div class="profile-channel__list-item-status" v-if="(true && isActive) || isDisabled">
        <PTag v-if="true" :color="'green'" text="Активен" />
        <PTag v-else-if="isDisabled" :color="'pink'" text="Заблокирован" />
      </div>
      <div v-if="channel.transport === ChannelsTransportType.wapi" class="profile-channel__list-item-tier">
          <PTag  color="fog" :text="`Уровень ${getTier}`">
            <template #right>
              <PTooltip
                placement="top"
              >
                <template class="flex flex-center" #activator>
                  <InfoIcon />
                </template>
                <template #text>
                  Вы можете инициировать {{}} сессий за сутки
                </template>
              </PTooltip>
            </template>
          </PTag>
      </div>
    </div>
  </div>
  <div v-if="divider" class="profile-channel__list-divider"></div>
</template>

<style scoped>
.profile-channel__list-item {
  box-sizing: border-box;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.profile-channel__list-item-btn {
  display: flex;
  gap: 16px;
}
.profile-channel__list-item-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.profile-channel__list-item-icon {
  display: flex;
}
.profile-channel__list-item-name {
  flex-grow: 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  width: 320px;
}
.profile-channel__list-item-status {
  /* width: 125px; */
  display: flex;
}

.profile-channel__list-item-popover {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.profile-channel__list-item-popover a {
  color: var(--el-color-primary);
}

.profile-channel__list-divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  width: 100%;
  margin: 8px 0;
}
.profile-channel__list-item-tier{
  
  .p-tag.color-fog:hover{
      opacity: 1;
    }
    :deep(){
      .p-tooltip-desktop{
        display: flex;
        align-items: center;
        justify-content: center;
        .container{
          transform: translateY(-12px);
          opacity: 1;
        }
        .text{
          width: 264px;
          font-family: Inter;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;

        }
        .target{
          width: 16px;
          height: 16px;
        }
      }
    
  }    
}


</style>
