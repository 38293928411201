import { createRouter, createWebHistory } from "vue-router";

import { useNotification } from '~/stores/notification.store';
import { authGuard } from '~/guards/auth.guard';
import ProfileView from '~/views/ProfileView.vue';
import { authPaths, initUser } from '~/composables/initUser';
import { lazy } from './lazy';
import { authRoutes } from './auth.routes';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...authRoutes,

    {
      path: "/broadcast",
      name: "broadcast",
      redirect: "/broadcast",
      children: [
        {
          path: "",
          name: "statistics",
          component: lazy(import("~/views/StatisticsView.vue")),
          meta: {
            pageTitle: "menu.mass-mailing",
          },
        },
        {
          path: 'sender',
          name: 'broadcastSender',
          component: lazy(import('~/components/broadcastV2/BroadcastV2Sender.vue')),
          meta: {
            pageTitle: "menu.send",
            withBackRedirect: true,
            background: "#FFFFFF",
          },
        },
      ],
    },
    {
      path: "/chats",
      name: "chats",
      component: lazy(import("~/views/ChatsView.vue")),
      meta: {
        pageTitle: "menu.chats",
      },
    },
    {
      path: "/chat-bots",
      name: "ChatBots",
      component: lazy(import("~/views/chat-bots/ChatBotsView.vue")),
      meta: {
        pageTitle: "menu.chat-bots",
      },
      children: [
        {
          path: '/chat-bots/:id',
          name: 'ChatBotsEditor',
          component: lazy(import('~/views/chat-bots/ChatbotEditor.vue')),
          meta: {
            canEditTitle: true,
            withBackRedirect: true,
          },
        },
      ],
    },
    {
      path: '/templates',
      name: 'templates',
      component: lazy(import('~/views/TemplatesView.vue')),
      meta: {
        pageTitle: "menu.templates",
      },
    },
    {
      path: "/trigger-broadcast",
      name: "triggerBroadcast",
      redirect: "/trigger-broadcast/list",
      meta: {
        pageTitle: "menu.trigger-broadcast",
      },
      children: [
        {
          path: '/trigger-broadcast/list',
          name: 'triggerBroadcastList',
          component: lazy(import('~/views/trigger/TriggerList.vue')),
        },
        {
          path: '/trigger-broadcast/edit',
          name: 'triggerBroadcastNew',
          component: lazy(import('~/views/trigger/TriggerEditForm.vue')),
          meta: {
            withBackRedirect: true,
          },
        },
        {
          path: '/trigger-broadcast/edit/:id',
          name: 'triggerBroadcastEdit',
          component: lazy(import('~/views/trigger/TriggerEditForm.vue')),
          meta: {
            withBackRedirect: true,
          },
        },
      ],
    },
    {
      path: "/errors/chatbot",
      name: "chatbot-error",
      component: lazy(import("~/components/app/error/ChatBot.vue")),
      meta: {
        layout: "AuthLayout",
      },
    },
    {
      path: "/errors/forbidden",
      name: "forbidden-error",
      component: lazy(import("~/components/app/error/Forbidden.vue")),
      meta: {
        layout: "AuthLayout",
      },
    },
    {
      path: "/errors/integration",
      name: "integration-error",
      component: lazy(import("~/components/app/error/Integration.vue")),
      meta: {
        layout: "AuthLayout",
      },
    },
    {
      path: "/errors/forbidden",
      name: "forbidden-error",
      component: lazy(import("~/components/app/error/Forbidden.vue")),
      meta: {
        layout: "AuthLayout",
      },
    },
    {
      path: "/errors/integrated",
      name: "integrated-error",
      component: lazy(import("~/components/app/error/Integrated.vue")),
      meta: {
        layout: "AuthLayout",
      },
    },
    {
      path: "/errors/chatbot",
      name: "chatbot-error",
      component: lazy(import("~/components/app/error/ChatBot.vue")),
      meta: {
        layout: "AuthLayout",
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: ProfileView,
      meta: {
        pageTitle: "menu.profile",
      },
    },
    {
      path: "/contacts",
      name: "contacts",
      component: lazy(import("~/views/contacts/ContactsView.vue")),
      children: [
        {
          path: "",
          name: "contactsList",
          component: lazy(import("~/views/contacts/ContactsListView.vue")),
          meta: {
            pageTitle: "menu.customers",
            background: "#FFFFFF",
          },
        },
        {
          path: "upload",
          name: "contactsUpload",
          component: lazy(import("~/views/contacts/ContactsUploadView.vue")),
          meta: {
            pageTitle: "menu.customers-upload",
            withBackRedirect: false,
          },
        },
        {
          path: 'upload/v2/new',
          name: 'contactUploadV2',
          component: lazy(import('~/views/contacts/v2/Upload.vue')),
          meta: {
            pageTitle: 'menu.customers-upload',
            withBackRedirect: true,
          }
        },
      ],
    },
    {
      path: "/",
      redirect: { name: "profile" },
    },
  ],
});

router.beforeEach(authGuard(authPaths));
router.beforeEach((to) => {
  const notificationStore = useNotification();

  notificationStore.setOffset(0);

  if (to.meta?.notificationOffset) {
    notificationStore.setOffset(to.meta?.notificationOffset as number);
  }
});
router.beforeEach(initUser);

export default router;
