import { defineStore } from 'pinia';

import { api } from '~/api';
import { useChannels } from './channel.store';
import { ChannelState } from '~/entities/channel';

interface State {
  isFetching: boolean;
  confirmationUrl: string;
}

export const useProfile = defineStore('profile', {
  state: (): State => ({
    isFetching: false,
    confirmationUrl: '',
  }),

  actions: {
    async fetchEditIFrame(id: string): Promise<string> {
      const { getWabaCurrent } = useChannels();
      const channelId = id || getWabaCurrent!.subscriptionId;
      const response = await api.profile.getEditProfileIframeLink(channelId);

      return response.link;
    },
    setConfirmationUrl(url: string) {
      this.confirmationUrl = url;
    },
    setFetching(payload: boolean) {
      this.isFetching = payload;
    },
    toCurrentChannel() {
      const channelStore = useChannels();

      const id = channelStore.getWabaCurrent!.id;

      this.router.push({
        name: 'currentChannel',
        params: {
          id,
        },
      });
    },
  },

  getters: {
    getFetching: (state: State) => state.isFetching,

    // Если у пользователя нет незакрытых каналов + нет подписки или статус подписки - "закрыто"
    isInitChannel(): boolean {
      const channelStore = useChannels();

      const filtered = channelStore.getNonClosedChannels;
      const isTariffView = this.router.currentRoute.value.name === 'tariffs';

      return (
        filtered[0]?.state === ChannelState.init &&
        !isTariffView
      );
    },
    noChannel: (): boolean => {
      const channelStore = useChannels();
      return channelStore.getChannels.length < 1;
    },
    haveNoNegativeStatusChannel: (): boolean => {
      const channelStore = useChannels();
      const notDeletedChannels = channelStore.getChannels.filter(channel => ![ChannelState.blocked, ChannelState.rejected, ChannelState.disabled].includes(channel.state))
      return notDeletedChannels.length < 1;
    },
    noActiveChannel: (): boolean => {
      const channelsStore = useChannels();
      const channels = channelsStore.getNonClosedChannels;

      return !channels.find(({ state }) => state === ChannelState.active);
    },

    // alerts
  },
});
