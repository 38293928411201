import { getUrlTriggerWebhookRead } from "~/views/trigger/utils/core";
import { TriggerFormData } from "~/stores/trigger-form.store";
import { config } from "~/config";
import { BroadcastVariables } from "~/entities/broadcast";
import { BroadcastDelay } from "~/views/trigger/types";
import { FileData } from "~/stores/broadcast.store";
import { broadcastVariablesMapToBroadcastVariables } from "~/views/trigger/utils/helpers";
import { HttpStatusCode } from "axios";
import { BaseCrudApi } from "./abstract/base-crud.api";

export class setTriggerRequestParams {
	id: string | null;
	templateId: string | undefined;
	title: string | undefined;
	botId: string | undefined;
	variables: BroadcastVariables;
	delay: BroadcastDelay | null;
	templateFile: FileData | null;
	subscriptionId: string
	fieldMap: Record<string, string>

	static fromTriggerFormData(dto: TriggerFormData, subscriptionId: string): setTriggerRequestParams {
		return {
			id: dto.id,
			templateId: dto.templateId,
			title: dto.title,
			botId: dto.botId,
			delay: dto.delay,
			templateFile: dto.templateFile,
			fieldMap: dto.fieldMap,
			variables: broadcastVariablesMapToBroadcastVariables(dto.variables),
			subscriptionId
		}
	}
}

export type GetWebhookResponseT = {
	data: Record<string, unknown>,
	meta: {
		birthtime: string
	}
} | null;


export class TriggerApi extends BaseCrudApi {
	getWebhook(triggerId: string): Promise<GetWebhookResponseT> {
		const url = getUrlTriggerWebhookRead(triggerId);
		return this.api.get(url).then((res) => {
			if (res.status === HttpStatusCode.Ok) {
				return res.data;
			}
			return null;
		});
	}
	setTrigger(params: setTriggerRequestParams) {
		return this.api.post(`${config.host}api/trigger`, params).then((res) => res.data);
	}
	getList() {
		return this.api.get(`${config.host}api/trigger/list`).then((res) => res.data);
	}
}
