
export enum TemplateStatus {
  approved = 'APPROVED',
  rejected = 'REJECTED',
  draft = 'DRAFT',
}

export enum TemplateComponentType {
  HEADER = 'HEADER',
  FOOTER = 'FOOTER',
  BUTTONS = 'BUTTONS',
  BODY = 'BODY',
}

export enum TemplateCategoryType {
  MARKETING = 'MARKETING',
  UTILITY = 'UTILITY',
  AUTHENTICATION = 'AUTHENTICATION',
}

export const TemplateComponentSortOrder = [
  TemplateComponentType.HEADER,
  TemplateComponentType.BODY,
  TemplateComponentType.FOOTER,
  TemplateComponentType.BUTTONS,
];

/* Только для component.HEADER */
export enum TemplateContentType {
  IMAGE = 'IMAGE',
  DOCUMENT = 'DOCUMENT',
  VIDEO = 'VIDEO',
  TEXT = 'TEXT',
}

export enum TemplateComponentFormat {
  IMAGE = 'IMAGE',
  DOCUMENT = 'DOCUMENT',
  VIDEO = 'VIDEO',
  TEXT = 'TEXT',
}

export enum TemplateComponentButtonType {
  URL = 'URL',
  QUICK_REPLY = 'QUICK_REPLY',
  PHONE_NUMBER = 'PHONE_NUMBER',
}

export interface TemplateButtonProperty {
  text: string;
  type: TemplateComponentButtonType;
  url?: string | null;
  example?: string[] | null;
  phoneNumber?: string | null;
}

export const FormatWithFiles = [
  TemplateContentType.DOCUMENT,
  TemplateContentType.IMAGE,
  TemplateContentType.VIDEO,
];
export const HeaderFileFormat: {
  [key in TemplateContentType]?: { formats: string[]; size: number };
} = {
  [TemplateContentType.IMAGE]: {
    formats: ['.jpg', '.jpeg', '.png'],
    size: 5,
  },
  [TemplateContentType.DOCUMENT]: {
    formats: ['.pdf'],
    size: 50,
  },
  [TemplateContentType.VIDEO]: {
    formats: ['.mp4', '.3gpp'],
    size: 16,
  },
};

export enum TemplateLanguage {
  RU = 'ru',
}

export type WabaChannelTemplate = {
  id: string;
  name: string;
  type: TemplateContentType;
  language: TemplateLanguage;
  category: TemplateCategoryType;
  body: string;
  bodyExamples: string[];
  header: string;
  headerExample: string;
  footer: string;
  namespace: string; // guid
  buttons: TemplateButtonProperty[] | null;
  status: TemplateStatus;
  rejectedReason: string | null;
  subscriptionId: string; // guid
  wabaId: string;
}

export enum TemplateType {
  wabaTemplate = 'wabaTemplate',
  wazzupTemplate = 'wazzupTemplate',
}

export const withFile: (template: WabaChannelTemplate) => boolean = (template) => {
  return !!template.header;
}