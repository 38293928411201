<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { SyncChannelModalProps } from './types/IndexProps';

const props = defineProps<SyncChannelModalProps>();
const emit = defineEmits<{
	close: [],
	valueChanged: [ string ],
	submit: [ string ]
}>()

const isVisible = computed<boolean>({
	get() {
		return props.visible
	},
	set() {
		emit('close')
	}
})

const value = ref<string>('');

watch(
	() => value.value, 
	(value) => {
		emit('valueChanged', value)
	}
)

</script>

<template>
<PDialog
  v-model="isVisible"
  width="x-small"
  :loading="false"
>
  <template #header>
    <header>
      <p>{{ $t('profile.modals.syncChannels.title') }}</p>
    </header>
  </template>
  <div class="main">
    <PInput
      v-model="value"
      size="large"
      type="text"
      :placeholder="$t('profile.modals.syncChannels.inputPlaceholder')"
    />
  </div>
  <template #footer>
    <footer>
      <PButton :disabled="false" class="button" @click="emit('submit', value)">Ок</PButton>
    </footer>
  </template>
</PDialog>

</template>

<style lang="scss" scoped>
header {
  padding: 12px 16px;
  border-bottom: 1px solid var(--common-rain);

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--common-black, #2B3238);
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;

  p {
    flex: 1;
    text-align: center;
  }

  .icon {
    cursor: pointer;
  }
}

.main {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  p {
    color: var(--common-black);
    text-align: center;
    font-size: 14px;
    line-height: 142%;
  }
}

footer {
  padding: 8px 16px 16px;

  .button {
    width: 100%;
  }
}
</style>
