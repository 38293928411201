import { defineStore } from "pinia";

import {
  WabaChannelTemplate,
  TemplateStatus,
  TemplateType,
} from "~/entities/template";
import { api } from "~/api";
import { useChannels } from "~/stores/channel.store";
import { ERROR_CHANNEL_NOT_DEFINED } from "~/constants/templateStore.constants";
import { BroadcastVariables } from "./broadcast.store";

type Templates = Array<WabaChannelTemplate>;

interface State {
  templates: Templates;
  channelTemplates: Templates;
  iframeUrl: string;
  isFetching: boolean;
}

const MAX_BODY_LENGTH = 1024;
const MAX_HEADER_LENGTH = 60;

export const useTemplates = defineStore("templates", {
  state: (): State => ({
    templates: [],
    channelTemplates: [],
    iframeUrl: "",
    isFetching: false,
  }),

  actions: {
    async fetchAndSync() {
      //this.templates = await api.templates.findManyAndSync();
    },
    async fetchIframe(type: TemplateType) {
      const { link } = await api.templates.getIframe(type);
      this.iframeUrl = link;
    },
    async fetchChannelTemplates() {
      const channelsStore = useChannels();
      const channel = channelsStore.selectedWabaChannel;

      if (!channel) {
        throw new Error(ERROR_CHANNEL_NOT_DEFINED);
      }

      const templates = await api.templates.findByChannel(
        channel.subscriptionId
      );

      this.channelTemplates = templates.filter(
        (template) => template.status === TemplateStatus.approved
      );
    },
    setFetching(payload: boolean) {
      this.isFetching = payload;
    },
    setTemplates(payload: Templates) {
      this.templates = payload;
    },
    validateTemplate(templateId: string, variables: BroadcastVariables) {
      const template = this.templates.find((t) => t.id === templateId);
      if (!template) return true;
      const bodyVaribles = Object.values(variables.bodyVars);
      const headerVaribles = Object.values(variables.headerVars);
      const bodyLength =
        template.body.length +
        bodyVaribles.join("").length -
        bodyVaribles.length * 5;
      let headerLength = template.header ? template.header.length : 0;
      if (headerVaribles.length) {
        headerLength =
          headerLength +
          headerVaribles.join("").length -
          headerVaribles.length * 5;
      }
      return bodyLength <= MAX_BODY_LENGTH && headerLength <= MAX_HEADER_LENGTH;
    },
  },

  getters: {
    getIframe: (state: State) => state.iframeUrl,
    getIsFetching: (state: State): boolean => state.isFetching,
    getChannelTemplates: (state: State) => state.channelTemplates,
    getTemplates: (state: State) => state.templates,
    getTemplatesMap: (
      state: State
    ): { [templateId: string]: WabaChannelTemplate } => {
      return state.templates.reduce(
        (templates, currentTemplate) => ({
          ...templates,
          [currentTemplate.id]: currentTemplate,
        }),
        {}
      );
    },
  },
});
