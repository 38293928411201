import { config } from "~/config";

export function getUrlTriggerWebhookWrite(triggerId: string) {
  return `${config.host}api/trigger/${triggerId}`;
}

export function getUrlTriggerWebhookRead(triggerId: string) {
  return `${config.host}api/trigger/webhook/${triggerId}`;
}

export function notNull(target: any) {
  return target !== null;
}
